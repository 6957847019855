import * as usersAPI from '../../api/users'
import { createPromiseThunk, reducerUtils, handleAsyncActions } from '../../util/asyncUtils'

const PUT_USER = 'POST_USER'
const PUT_USER_SUCCESS = 'POST_USER_SUCCESS'
const PUT_USER_ERROR = 'POST_USER_ERROR'

const GET_USER = 'GET_USER'
const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
const GET_USER_ERROR = 'GET_USER_ERROR'

const initialState = {
    put_user: reducerUtils.initial(),
    get_user: reducerUtils.initial(),
}

export const putUser = createPromiseThunk(PUT_USER, usersAPI.putUser)
export const getUser = createPromiseThunk(GET_USER, usersAPI.getUser)

export default function users(state = initialState, action) {
    switch (action.type) {
        case PUT_USER:
        case PUT_USER_SUCCESS:
        case PUT_USER_ERROR:
            return handleAsyncActions(PUT_USER, 'post_user')(state, action)
        case GET_USER:
        case GET_USER_SUCCESS:
        case GET_USER_ERROR:
            return handleAsyncActions(GET_USER, 'get_user')(state, action)
        default:
            return state
    }
}
