import Layout from "./layout";
import { Container } from "react-bootstrap";
import { Collapse, Tabs } from "antd";
const { Panel } = Collapse;
const { TabPane } = Tabs;

export default function FaqList({ data }) {
  const callback = (key) => {
  };

  const createMarkup = (htmlcode) => {
    return {
      __html: htmlcode,
    };
  };

  
  return (
    <Layout>
      <Container style={{ padding: "20px 0" }}>
        <Tabs defaultActiveKey="1" onChange={callback} type="card">
          <TabPane tab="전체" key="0">
            <Collapse onChange={callback} expandIconPosition="right">
              {data.map((item, idx) => (
                <Panel
                  header={
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="faq_title_category">
                        {item.title.split("-")[0]}
                      </div>
                      <div className="faq_title">
                        Q. {item.title.split("-")[1]}
                      </div>
                    </div>
                  }
                  key={idx}
                  showArrow={false}
                >
                  <div className="faq_answer_title">답변</div>
                  <div><div dangerouslySetInnerHTML={createMarkup(item.contents)} /></div>
                </Panel>
              ))}
            </Collapse>
          </TabPane>
          <TabPane tab="계정관리" key="2">
          <Collapse onChange={callback} expandIconPosition="right">
              {data.map((item, idx) => (
                item.title.split("-")[0]==="계정관리" ? <Panel
                  header={
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="faq_title_category">
                        {item.title.split("-")[0]}
                      </div>
                      <div className="faq_title">
                        Q. {item.title.split("-")[1]}
                      </div>
                    </div>
                  }
                  key={idx}
                  showArrow={false}
                >
                  <div className="faq_answer_title">답변</div>
                  <div><div dangerouslySetInnerHTML={createMarkup(item.contents)} /></div>
                </Panel>
              : ''))}
            </Collapse>
          </TabPane>
          <TabPane tab="보안" key="3">
          <Collapse onChange={callback} expandIconPosition="right">
              {data.map((item, idx) => (
                item.title.split("-")[0]==="보안" ? <Panel
                  header={
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="faq_title_category">
                        {item.title.split("-")[0]}
                      </div>
                      <div className="faq_title">
                        Q. {item.title.split("-")[1]}
                      </div>
                    </div>
                  }
                  key={idx}
                  showArrow={false}
                >
                  <div className="faq_answer_title">답변</div>
                  <div><div dangerouslySetInnerHTML={createMarkup(item.contents)} /></div>
                </Panel>
              :''))}
            </Collapse>
          </TabPane>
          <TabPane tab="파트너사 코인/AZIT교환" key="4">
          <Collapse onChange={callback} expandIconPosition="right">
              {data.map((item, idx) => (
                item.title.split("-")[0].includes("파트너사") ? <Panel
                  header={
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="faq_title_category">
                        {item.title.split("-")[0]}
                      </div>
                      <div className="faq_title">
                        Q. {item.title.split("-")[1]}
                      </div>
                    </div>
                  }
                  key={idx}
                  showArrow={false}
                >
                  <div className="faq_answer_title">답변</div>
                  <div><div dangerouslySetInnerHTML={createMarkup(item.contents)} /></div>
                </Panel>
              :''))}
            </Collapse>
          </TabPane>
          <TabPane tab="AZIT 토큰 전송" key="5">
          <Collapse onChange={callback} expandIconPosition="right">
              {data.map((item, idx) => (
                item.title.split("-")[0].includes("토큰") ? <Panel
                  header={
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="faq_title_category">
                        {item.title.split("-")[0]}
                      </div>
                      <div className="faq_title">
                        Q. {item.title.split("-")[1]}
                      </div>
                    </div>
                  }
                  key={idx}
                  showArrow={false}
                >
                  <div className="faq_answer_title">답변</div>
                  <div><div dangerouslySetInnerHTML={createMarkup(item.contents)} /></div>
                </Panel>
              :''))}
            </Collapse>
          </TabPane>
          <TabPane tab="월렛 기능" key="6">
          <Collapse onChange={callback} expandIconPosition="right">
              {data.map((item, idx) => (
                item.title.split("-")[0]==="월렛 기능" ? <Panel
                  header={
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="faq_title_category">
                        {item.title.split("-")[0]}
                      </div>
                      <div className="faq_title">
                        Q. {item.title.split("-")[1]}
                      </div>
                    </div>
                  }
                  key={idx}
                  showArrow={false}
                >
                  <div className="faq_answer_title">답변</div>
                  <div><div dangerouslySetInnerHTML={createMarkup(item.contents)} /></div>
                </Panel>
              :''))}
            </Collapse>
          </TabPane>
        </Tabs>
      </Container>
    </Layout>
  );
}
