import * as faqAPI from "../../api/faq";
import {
  createPromiseThunk,
  reducerUtils,
  handleAsyncActions,
} from "../../util/asyncUtils";

const GET_FAQ = "GET_FAQ";
const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS";
const GET_FAQ_ERROR = "GET_FAQ_ERROR";

const initialState = {
  get_faq: reducerUtils.initial(),
};

export const getFaq = createPromiseThunk(
  GET_FAQ,
  faqAPI.getAllFaq
);

export default function system(state = initialState, action) {
  switch (action.type) {
    case GET_FAQ:
    case GET_FAQ_SUCCESS:
    case GET_FAQ_ERROR:
      return handleAsyncActions(GET_FAQ, "get_faq")(state, action);
    default:
      return state;
  }
}
