
const config = {
  backend: {
    baseUrl: 'https://api.azit.app/v1',
  },
  kas: {
    accessKeyId: process.env.KAS_ACCESS_KEY_ID,
    secretAccessKey: process.env.KAS_SECRET_ACCESS_KEY,
    chainId: process.env.KAS_CHAIN_ID,
  },
}

export default config
