import * as noticeAPI from "../../api/notice";
import {
  createPromiseThunk,
  reducerUtils,
  handleAsyncActions,
} from "../../util/asyncUtils";

const GET_NOTICES = "GET_NOTICES";
const GET_NOTICES_SUCCESS = "GET_NOTICES_SUCCESS";
const GET_NOTICES_ERROR = "GET_NOTICES_ERROR";
const GET_NOTICE = "GET_NOTICE";
const GET_NOTICE_SUCCESS = "GET_NOTICE_SUCCESS";
const GET_NOTICE_ERROR = "GET_NOTICE_ERROR";

const initialState = {
  get_notices: reducerUtils.initial(),
  get_notice: reducerUtils.initial(),
};

export const getNotices = createPromiseThunk(
  GET_NOTICES,
  noticeAPI.getAllNotices
);
export const getNotice = createPromiseThunk(GET_NOTICE, noticeAPI.getOneNotice);

export default function system(state = initialState, action) {
  switch (action.type) {
    case GET_NOTICES:
    case GET_NOTICES_SUCCESS:
    case GET_NOTICES_ERROR:
      return handleAsyncActions(GET_NOTICES, "get_notices")(state, action);
    case GET_NOTICE:
    case GET_NOTICE_SUCCESS:
    case GET_NOTICE_ERROR:
      return handleAsyncActions(GET_NOTICE, "get_notice")(state, action);
    default:
      return state;
  }
}
