export default function EmptyScreen({ image, title }) {
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh", alignItems: "center", justifyContent: "center" }}>
      <div>
        <img src={image} width={144} />
      </div>
      <div style={{ color: "#878c9e", fontSize: '14px' }}>{title}</div>
    </div>
  );
}
