import Layout from "./layout";
import styles from "../styles/noticeList.css";
import Pagination from "./pagination";
import { Container } from "react-bootstrap";
import EmptyScreen from "./emptyScreen";

export default function NoticeList({ data }) {
  const checkAfter2Weeks = (date) => {
    const upload_date = new Date(date);
    const today = new Date();
    const two_weeks_ago = new Date(today.setDate(today.getDate() - 14));

    return upload_date > two_weeks_ago;
  };
  return (
    <Layout>
      <Container>
        <div className={data[0].length !== 0 ? "wrapper" : "wrapper_empty"}>
          {data[1].map((item, idx) =>
            item.is_pinned ? (
              <div key={idx} className="list_pin">
                <div className="list_pin_wrapper">
                  <div className="list_pin_left_box">
                    <div style={{ marginRight: "8px" }}>
                      <img src="/images/speaker.png" width={20} />
                    </div>
                    <div className="list_pin_title">
                      <a href={"/notice/" + item.id}>
                        <a className="special_a">{item.title} </a>
                      </a>
                    </div>
                  </div>
                  {/* <div className="list_pin_date">
                    {item.updated_at.substring(0, 10)}
                  </div> */}
                </div>
              </div>
            ) : (
              ""
            )
          )}

          {data[1].length === 0 ? (
            <EmptyScreen image="/images/computer_azi.png" title="등록된 공지사항이 없어요." />
          ) : (
            data[1].map((item, idx) => (
              <div key={idx} className="list">
                <div className="list_left_box_notice">
                  <div className="list_category_notice">
                    <div>{item.category_name}</div>
                  </div>
                  <div className="list_date">{item.updated_at.substring(0, 10)}</div>
                </div>
                <div className="list_wrapper">
                  <div className="list_left_box">
                    <div className="list_title">
                      <a href={"/notice/" + item.id}>
                        <a className="basic_a">
                          {item.title} {checkAfter2Weeks(item.updated_at) ? <img src="/images/images-notice-new@2x.png" width={16} /> : ""}
                        </a>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
          {/* <Pagination pageNum={data[1].length} /> */}
        </div>
        <style jsx>{`
          .notice_menu {
            text-decoration: none;
          }

          .faq_menu {
            text-decoration: none;
          }

          a.basic_a {
            text-decoration: none;
            color: #2e313d;
          }

          a.special_a {
            text-decoration: none;
            color: #2e313d;
          }
        `}</style>
      </Container>
    </Layout>
  );
}
