import './App.css';
import { Routes, Route } from 'react-router-dom'
import Main from './Views/Main'
import NotFound from './Views/NotFound';
import Pi from './Views/Pi';
import Service from './Views/Service';
import Notice from './Views/Notice';
import Event from './Views/Event';
import FAQ from './Views/FAQ';
import Partner from './Views/Partner';
import NoticeDetailContainer from './containers/noticeDetailContainer';
import EventDetailContainer from './containers/eventDetailContainer';
import Pi2 from './Views/Pi2';
import PartnerAgreement from './Views/PartnerAgreement';
import PartnerNotice from './Views/PartnerNotice';
import Survey from './Views/Survey';
import Location from './Views/Location';
import NftTerm from './Views/NftTerm';
import TermDetailContainer from './containers/TermDetailContainer';

function App() {
  return (<Routes>
      <Route path="/" exact element={<Main />} />
      <Route path="/service" exact element={<TermDetailContainer id="3"/>} />
      <Route path="/pi" exact element={<TermDetailContainer id="1"/>} />
      <Route path="/pi2" exact element={<TermDetailContainer id="2"/>} />
      <Route path="/location" exact element={<TermDetailContainer id="4"/>} />
      <Route path="/challenge" exact element={<TermDetailContainer id="22"/>} />
      <Route path="/termsofservice" exact element={<TermDetailContainer id="23"/>} />
      <Route path="/privacypolicy" exact element={<TermDetailContainer id="24"/>} />
      <Route path="/service" exact element={<TermDetailContainer id="3"/>} />
      <Route path="/nft" exact element={<TermDetailContainer id="6"/>} />
      <Route path="/nft_terms" exact element={<NftTerm />} />
      <Route path="/notice" exact element={<Notice />} />
      <Route path="/notice/:pid" exact element={<NoticeDetailContainer />} />
      <Route path="/event" exact element={<Event />} />
      <Route path="/event/:pid" exact element={<EventDetailContainer />} />
      <Route path="/faq" exact element={<FAQ />} />
      <Route path="/partners/:partner_code" exact element={<Partner />} />
      <Route path="/partners/:partner_code/agreement" exact element={<PartnerAgreement />} />
      <Route path="/partners/:partner_code/notice" exact element={<PartnerNotice />} />
      <Route path="/survey" exact element={<Survey />} />
      <Route path="/ziptoss-consent" exact element={<TermDetailContainer id="5"/>} />
      <Route path="*" exact element={<NotFound />} />
  </Routes>);
}

export default App;
