import axios from "axios";
import config from "../config";

axios.defaults.headers["Pragma"] = "no-cache";

const server = config.backend.baseUrl;

export const getAllEvents = async (data) => {
  try {
    const response = await axios({
      method: "get",
      url: server + "/events?pageCode=MAIN",
    });
    const result = response.data.data.filter((item) => !item.url.includes("notice"));
    return result;
  } catch (error) {
    throw error.response.data;
  }
};

export const getOneEvent = async (data) => {
  try {
    const response = await axios({
      method: "get",
      url: server + "/events/" + data.pid,
    });
    console.log(response.data);
    return response.data.data;
  } catch (error) {
    throw error.response.data;
  }
};
