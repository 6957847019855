import * as termAPI from "../../api/term";
import {
  createPromiseThunk,
  reducerUtils,
  handleAsyncActions,
} from "../../util/asyncUtils";

const GET_TERM = "GET_TERM";
const GET_TERM_SUCCESS = "GET_TERM_SUCCESS";
const GET_TERM_ERROR = "GET_TERM_ERROR";

const initialState = {
  get_term: reducerUtils.initial(),
};

export const getTerm = createPromiseThunk(
  GET_TERM,
  termAPI.getTerm
)


export default function system(state = initialState, action) {
  switch (action.type) {
    case GET_TERM:
    case GET_TERM_SUCCESS:
    case GET_TERM_ERROR:
      return handleAsyncActions(GET_TERM, "get_term")(state, action);
    default:
      return state;
  }
}
