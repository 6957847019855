import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

function Partner() {
  const { partner_code } = useParams();
  const [partnerName, setPartnerName] = useState("");
  useEffect(() => {
    if (partner_code === "DABANG") {
      setPartnerName("다방");
    } else if (partner_code === "WM") {
      setPartnerName("위매치");
    }
  });

  return (
    <>
      <div>
        <div className="what_coin_desc">
          <div className="what_coin_title">
            <div>다방코인이란?</div>{" "}
            <div>
              <img src="/images/emoji-face-monocle-face@2x.png" width="22px" />
            </div>
          </div>
          <div className="what_coin_desc_detail">
            <div>
              대한민국 대표 부동산 플랫폼 다방만의 특별한 리워드 서비스로 다방
              포인트로 전환하여 다방의 특화된 상품 구매나 서비스 이용 결제 등에
              활용할 수 있습니다.
            </div>
            <div></div>{" "}
          </div>
          <div className="what_coin_desc_detail">
            <div>
              또한 AZIT(아지트 토큰)으로 전환을 통해 주거, 생활, 가상화폐 생태계
              등 일상에서 다양하게 활용할 수 있습니다.
            </div>
            <div></div>{" "}
          </div>
        </div>
        <div className="partner_box">
          <div className="partner_title">{partnerName}코인 적립</div>
          <div className="partner_body">
            ∙ {partnerName}코인은 다방의 상품이나 특정 서비스를 구매 및 이용하거나 다방이
            주최하는 이벤트, 적립 서비스 등 마케팅 활동에 참여하여 무료로 적립할
            수 있습니다.
          </div>
          <div className="partner_body partner_body_purple">
            ∙ {partnerName}코인 관련 세부정책은 다방 앱에서 확인하실 수
            있습니다.
          </div>
        </div>
        <div className="partner_box">
          <div className="partner_title">{partnerName}코인 사용</div>
          <div className="partner_body">
            ∙ {partnerName}코인은 다방 앱에서 다방 포인트로 전환하여 다방의 특화
            상품이나 서비스 이용에 결제할 수 있습니다.
          </div>
          <div className="partner_body">
            ∙ {partnerName}코인은 아지트(azit)에서 AZIT(아지트 토큰)으로 전환한
            후, 제휴사의 포인트로 전환하여 제휴사 서비스 이용에 활용하거나
            AZIT(아지트 토큰)가 상장되어 있는 가상자산 플랫폼을 통해 가상화폐
            생태계를 경험할 수 있습니다.
          </div>
          <div className="method_info">
            <div className="method_title">이용 방법 안내</div>
            <div>
              1. {partnerName}코인 → {partnerName}포인트
            </div>
            <div>2. {partnerName}코인 → AZIT → 제휴사 포인트</div>
            <div>
              3. {partnerName}코인 → AZIT → 가상자산 거래소 (Klayswap, MEXC)
            </div>
          </div>
        </div>
        <div className="partner_box">
          <div className="partner_title">{partnerName}코인 전환</div>
          <div className="partner_body">
            ∙ {partnerName}코인은 {partnerName} 및 아지트(azit) 앱에 회원가입 및
            연동을 완료한 후에 AZIT로 전환이 가능합니다.
          </div>
          <div className="method_info">
            <div className="method_title">이용 방법 안내</div>
            <div>
              아지트(azit) 홈 → [MY 포인트] <b>{partnerName}</b> → 교환하기
            </div>
          </div>
        </div>
        <div className="partner_box">
          <div className="partner_title">{partnerName}코인 전환 한도</div>
          <div className="partner_body">
            ∙ <b>1회</b>: 100 ~ 100,000C까지 전환 가능
            <br /> ∙ <b>1일</b>: 100 ~ 500,000C까지 전환 가능 <br /> ∙ 한도는
            다방의 정책이나 관련 법령에 따라 사전 고지 없이 변경될 수 있습니다.
          </div>
        </div>
        {/* <div className="partner_box">
      <div className="partner_title">{partnerName} 코인 유효기간</div>
      <div className="partner_body">- {partnerName}코인은 적립월로부터 최대 1년(12개월) 이며 소멸하기 30일 전부터 확인 가능합니다. <br/> - 이벤트로 적립된 {partnerName}코인은 별도의 유효기간이 설정될 수 있습니다. <br/> - 회원 탈퇴 시, 유효기간과는 무관하게 소멸 될 수 있습니다.</div>
    </div> */}
      </div>
    </>
  );
}

export default Partner;
