import axios from 'axios'
import config from '../config'

axios.defaults.headers['Pragma'] = 'no-cache'

const server = config.backend.baseUrl

export const putUser = async (data) => {
  try {
    const response = await axios.put(server + '/users', data)
    return response.data.data
  } catch (error) {
    throw error.response.data
  }
}

export const getUser = async (address) => {
  try {
    const response = await axios.get(server + `/users/${address}`)
    return response.data.data
  } catch (error) {
    throw error.response.data
  }
}
