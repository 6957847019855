import React from "react";
import EventContainer from "../containers/eventContainer";

function Event() {
  return (
    <>
      <div>
        <main>
          <EventContainer />
        </main>
      </div>
    </>
  );
}

export default Event;
