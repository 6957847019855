import axios from 'axios'
import config from '../config'

axios.defaults.headers['Pragma'] = 'no-cache'

const server = config.backend.baseUrl;
  
export const getAllNotices = async (data) => {
  let all_notices = []
    try {
    const response1 = await axios({
      method: "get",
      url: server+"/notices?pageNo=1",
    });
    
    console.log(response1);
    all_notices.push(response1.data.data)
    const response2 = await axios({
      method: "get",
      url: server + "/notices?pageSize="+1000,
    });
    all_notices.push(response2.data.data)
    console.log(all_notices)
    return all_notices
  } catch (error) {
    throw error.response1.data
  }
}

export const getOneNotice = async (data) => {
  try {
    const response = await axios({
      method: "get",
      url: server+"/notices/"+data.pid,
    });
    return response.data.data
  } catch (error) {
    throw error.response.data
  }
}
