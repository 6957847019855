import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Spin } from 'antd'
import { getTerm } from '../store/modules/term'
import TermDetail from '../components/termDetail'

function TermDetailContainer({id}) {
  console.log(id);
  const { data, loading, error } = useSelector((state) => state.term.get_term)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getTerm({id}))
  }, [dispatch,id])

  if (loading && !data)
    return (
      <div style={{ textAlign: 'center', paddingTop: '100px' }}>
        <Spin className="p-4" />
      </div>
    )
  if (error) return <div className="error-text" style={{ textAlign: 'center', paddingTop: '100px' }}>Error!</div>
  if (!data) return <div className="error-text" style={{ textAlign: 'center', paddingTop: '100px' }}>no data</div>
  return <TermDetail data={data} />
}

export default TermDetailContainer
