import React from 'react'

function PartnerAgreement() {
  const htmlcode = `<p>개인 정보 제3자 제공 동의&nbsp;</p>

  <p>회사는 이용자의 개인정보를 개인정보 처리방침 제1조(개인정보의 수집 및 처리목적)에서 명시한 범위 내에서만 처리하며, 회원의 사전 동의 없이 동 범위를 초과하여 이용하거나 회원의 개인정보를 제3자에게 제공하지 않습니다. (다만, 관련 법령에 의하거나 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관 등에 개인정보를 제공하여야 하는 경우에는 예외로 합니다.)&nbsp;</p>
  
  <p>회사는 서비스 이행을 위하여 회원의 동의를 전제로 아래에서 명시한 바와 같이 개인정보를 제3자에게 제공할 것입니다.&nbsp;</p>
  
  <p>회원은 본 개인정보의 제3자 제공에 대한 동의를 거부할 권리가 있습니다. 다만 동의를 거부하는 경우 아지트(azit) 전송서비스 및 제공받는 자와 관련된 포인트 연동 등의 서비스 이용이 제한됩니다.&nbsp;</p>
  
  <table border="1">
    <tbody>
      <tr>
        <td>
        <p>제공받는 자&nbsp;</p>
        </td>
        <td>
        <p>이용 목적&nbsp;</p>
        </td>
        <td>
        <p>제3자 제공 항목&nbsp;</p>
        </td>
        <td>
        <p>보유 및 이용 기간&nbsp;</p>
        </td>
      </tr>
      <tr>
        <td>
        <p>주식회사&nbsp;</p>
  
        <p>스테이션 3&nbsp;</p>
        </td>
        <td>
        <p>서비스 제휴(연동 및 해제, 적립, 사용, 조회, 회수, 반환 등), 민원 처리 등 소비자 분쟁 해결&nbsp;</p>
        </td>
        <td>
        <p>회원의 인증토큰값, 다방 코인 보유 현황 정보, 다방 코인 거래 정보&nbsp;</p>
        </td>
        <td>
        <p>아지트 계정 연동 해지 후 180일까지, 또는 관계 법령상 의무보존 기간 중 더 늦은 날까지&nbsp;</p>
        </td>
      </tr>
    </tbody>
  </table>
  
  <p>&nbsp;</p>
  
  <p>상기에 기재되지 않은 개인정보 취급과 관련된 일반 사항은 서비스의 개인정보 처리방침에 따릅니다.&nbsp;</p>
  
  
`

  function createMarkup() {
    return {
      __html: htmlcode
    };
  }

  return <div style={{padding:'28px', fontFamily:'Pretendard'}}><div dangerouslySetInnerHTML={createMarkup()} /></div>;
}

export default PartnerAgreement
