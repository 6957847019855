import Layout from "./layout";
import styles from "../styles/notice.css";
import { Container } from "react-bootstrap";

export default function TermDetail({ data }) {
  const createMarkup = (htmlcode) => {
    return {
      __html: htmlcode,
    };
  };

  return (
    <div className="container">
      <main>
        <Layout>
          <Container>
              <div className="noti_detail_body" style={{fontSize: '14px'}}>
                <div style={{ fontFamily: "Pretendard" }}>
                  <div dangerouslySetInnerHTML={createMarkup(data.contents)} />
                </div>
              </div>
          </Container>
        </Layout>
      </main>
      <style jsx>{`
        a {
          text-decoration: none;
          color: #2a323c;
        }

        th, td, tr {
          border: 1px solid #e5e5e5;
          padding: 2px;
        }

        th {
          background: #eee;
        }

      `}</style>
    </div>
  );
}
