import Layout from "./layout";
import styles from "../styles/eventList.css";
import Pagination from "./pagination";
import { Container } from "react-bootstrap";
import EmptyScreen from "./emptyScreen";

export default function EventList({ data }) {
  const compareDate = (start, end) => {
    const today = new Date();
    const startDate = new Date(start);
    const endDate = new Date(end);

    if (today < startDate) {
      return -1;
    } else if (today < endDate) {
      return 0;
    } else {
      return 1;
    }
  };
  return (
    <Layout>
      <Container>
        <div className={data.length !== 0 ?"wrapper":"wrapper_empty"}>
          {data.length !== 0 ? (
            data.map((item, idx) => (
              <div key={idx} className="list">
                <div className="list_wrapper_event">
                  <a href={"/event/" + item.id}>
                    <div className="list_left_box">
                      <div className="thumbnail_box" style={{ backgroundColor: item.background_color }}>
                        <img src={item.thumbnail_link} style={{ maxWidth: "200px", margin: "0 auto" }} />
                      </div>
                      <div className="list_title">
                        <div className="event_list_title">
                          <div className="basic_a event_status">
                            {item.status === "ready" ? "예정" : item.status === "ongoing" ? "진행중" : "마감"}
                          </div>
                          <div className="basic_a event_title">{item.title}</div>
                        </div>
                        <div className="basic_a event_period">
                          {item.start_time.substring(0, 10)}~{item.end_time.substring(0, 10)}
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            ))
          ) : (
            <EmptyScreen image="/images/benefit_azi.png" title="아직 진행중인 이벤트가 없어요."/>
          )}
          {/* <Pagination pageNum={data[1].length} /> */}
        </div>
        <style jsx>{`
          .notice_menu {
            text-decoration: none;
          }

          .faq_menu {
            text-decoration: none;
          }

          a.basic_a {
            text-decoration: none;
            color: #2e313d;
          }

          a.special_a {
            text-decoration: none;
            color: #2e313d;
          }
        `}</style>
      </Container>
    </Layout>
  );
}
