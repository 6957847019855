import React from "react";
import NoticeContainer from "../containers/noticeContainer";
import styles from "../styles/notice.css";
import { Map } from 'immutable'
import { useSelector } from 'react-redux'

function Notice() {
  const base = Map(useSelector((state) => state.base))
  const page = base.get('page')
  return (
    <div className={styles.container}>
      <main>
        <NoticeContainer offset={page} />
      </main>
    </div>
  );
}

export default Notice;
