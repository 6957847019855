import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Spin } from 'antd'
import NoticeList from '../components/noticeList'
import { getNotices } from '../store/modules/notice'

function NoticeContainer({ offset }) {
  const { data, loading, error } = useSelector((state) => state.notice.get_notices)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getNotices({offset: offset}))
  }, [dispatch, offset])

  if (loading && !data)
    return (
      <div style={{ textAlign: 'center', paddingTop: '100px' }}>
        <Spin className="p-4" />
      </div>
    )
  if (error) return <div className="error-text" style={{ textAlign: 'center', paddingTop: '100px' }}>Error!</div>
  if (!data) return <div className="error-text" style={{ textAlign: 'center', paddingTop: '100px' }}>no data</div>
  return <NoticeList data={data} />
}

export default NoticeContainer
