import axios from 'axios'
import config from '../config'

axios.defaults.headers['Pragma'] = 'no-cache'

const server = config.backend.baseUrl;
const token = config.backend.accessToken;

export const getAllFaq = async (data) => {
    try {
    const response1 = await axios({
      method: "get",
      url: server+"/faq?pageSize=100",
    });
    return response1.data.data
  } catch (error) {
    throw error.response1.data
  }
}
