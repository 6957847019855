import axios from 'axios'
import config from '../config'

axios.defaults.headers['Pragma'] = 'no-cache'

const server = config.backend.baseUrl;

export const getTerm = async (data) => {
  try {
    const response = await axios({
      method: "get",
      url: server+"/terms/"+data.id,
    });
    return response.data.data
  } catch (error) {
    throw error.response.data
  }
}
