import ImageMap from "image-map";
import { useEffect } from "react";

export default function Marketb({}) {
  useEffect(() => {
    console.log("event");
    ImageMap("img[usemap]", 500);
  }, []);
  return (
    <div>
      <img src="https://azit-nft.s3.ap-northeast-2.amazonaws.com/event/azit_marketb.png" usemap="#image-map" style={{ width: "100%" }} />

      <map name="image-map">
        <area
          target="_blank"
          alt=""
          title=""
          href="https://marketb.kr/product/detail.html?product_no=28779&amp;cate_no=1&amp;display_group=9"
          coords="800,3822,1361,4377"
          shape="rect"
        />
        <area
          target="_blank"
          alt=""
          title=""
          href="https://marketb.kr/product/detail.html?product_no=27999&amp;cate_no=1&amp;display_group=9"
          coords="1440,3821,1999,4376"
          shape="rect"
        />
        <area
          target="_blank"
          alt=""
          title=""
          href="https://marketb.kr/product/detail.html?product_no=21553&amp;cate_no=627&amp;display_group=1"
          coords="157,4456,718,5017"
          shape="rect"
        />
        <area
          target="_blank"
          alt=""
          title=""
          href="https://marketb.kr/product/detail.html?product_no=24112&amp;cate_no=663&amp;display_group=1"
          coords="800,4458,1360,5015"
          shape="rect"
        />
        <area
          target="_blank"
          alt=""
          title=""
          href="https://marketb.kr/product/detail.html?product_no=14853&amp;cate_no=3164&amp;display_group=1"
          coords="1440,4460,1999,5017"
          shape="rect"
        />
        <area
          target="_blank"
          alt=""
          title=""
          href="https://docs.google.com/forms/d/e/1FAIpQLSc5lS1-8-H-hpMAKV6napirqk4rzjeTcoId1eObxJ0YL2SjgQ/viewform"
          coords="159,7648,1997,7963"
          shape="rect"
        />
      </map>
      <div>
        <p>
          <strong>유의 사항</strong>
        </p>

        <ul>
          <li>
            <p>본 이벤트는 1인 1계정 참여 가능한 이벤트입니다.</p>
          </li>
          <li>
            <p>신규 / 기존 유저 모두 참여 가능합니다.</p>
          </li>
          <li>
            <p>신청한 이메일 주소는 변경 및 수정이 불가합니다.</p>
          </li>
          <li>
            <p>아지트 APP 회원가입(본인인증)을 정상적으로 완료해야 이벤트에 참여가 가능합니다.</p>
          </li>
          <li>
            <p>이벤트 물품, 수량은 당사의 사정 및 재고 상황에 의해 변경될 수 있습니다.</p>
          </li>
          <li>
            <p>개인정보 보호법에 따라 동의를 얻어 개인 정보를 수집 일로부터 2년간 보관합니다.</p>
          </li>
          <li>
            <p>개인 정보 수집에 거부한 경우 각종 소식 및 이벤트 정보 수신에 제한이 있을 수 있습니다.</p>
          </li>
          <li>
            <p>이벤트 당첨자 발표일 까지 회원인 상태를 유지해야 이벤트 당첨자에 포함됩니다.</p>
          </li>
          <li>
            <p>그 외 어떤 용도와 방식으로도 개인 정보를 활용/유출하지 않습니다.</p>
          </li>
          <li>
            <p>제세공과금은 당사에서 부담합니다.</p>
          </li>
        </ul>
      </div>
    </div>
  );
}
