import { combineReducers } from "redux";
import users from "./users";
import base from "./base";
import notice from "./notice";
import faq from "./faq";
import event from "./event";
import term from "./term";

const rootReducer = combineReducers({
  users,
  base,
  notice,
  event,
  faq,
  term,
});

export default rootReducer;
