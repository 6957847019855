import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Spin } from 'antd'
import EventList from '../components/eventList'
import { getEvents } from '../store/modules/event'

function EventContainer({ }) {
  const { data, loading, error } = useSelector((state) => state.event.get_events)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getEvents({}))
  }, [dispatch])

  if (loading && !data)
    return (
      <div style={{ textAlign: 'center', paddingTop: '100px' }}>
        <Spin className="p-4" />
      </div>
    )
  if (error) return <div className="error-text" style={{ textAlign: 'center', paddingTop: '100px' }}>Error!</div>
  if (!data) return <div className="error-text" style={{ textAlign: 'center', paddingTop: '100px' }}>no data</div>
  return <EventList data={data} />
}

export default EventContainer
