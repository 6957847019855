import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

function Survey() {
  const { partner_code } = useParams();
  const [partnerName, setPartnerName] = useState("");
  useEffect(() => {
    window.location = 'https://forms.gle/e8tKV2hdRWNkn6j37';
  });

  return (
    <>
      <div>
        
      </div>
    </>
  );
}

export default Survey;
