import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

function PartnerNotice() {
  const { partner_code } = useParams();
  const [partnerName, setPartnerName] = useState("");
  useEffect(() => {
    if (partner_code === "DABANG") {
      setPartnerName("다방");
    } else if (partner_code === "WM") {
      setPartnerName("위매치");
    }
  });

  return (
    <>
      <div>
        <div className="partner_box">
          <div className="partner_title">[적립]</div>
          <div className="partner_body">
            다방코인은 회원이 당사나 제휴서비스의 상품이나 특정 서비스를 구매 및
            이용하거나 당사가 주최하는 이벤트, 적립 서비스 등 마케팅 활동 등
            참여하여 적립 할 수 있습니다.
          </div>
          <div className="partner_body">
            적립시기는 당사 또는 제휴사와의 약정으로 별도 고지한 바에 따르며
            이와 관련하여 제세공과금 발생 시 회원이 부담할 수 있습니다.
          </div>
        </div>
        <div className="partner_box">
          <div className="partner_title">[계정 연동]</div>
          <div className="partner_body">
            다방코인을 아지트 토큰(AZIT) 및 마일리지로 전환하는 서비스는 아지트
            회원과 1:1 연동을 통하여 가능하며 복수의 계정 연동은 불가능 합니다.
          </div>
          <div className="partner_body">
            계정 연동을 위해서는 다방 및 아지트 회원이어야 하며 다방
            어플리케이션 또는 아지트 어플리케이션에서 요청 할 수 있습니다.
          </div>
          <div className="partner_body">
            계정 연동은 언제든 해제할 수 있으며 해제 시에도 잔여 다방코인은
            소멸되지 않습니다.
          </div>
          <div className="partner_body">
            단, 회원탈퇴 시 잔여 다방코인은 소멸됩니다.
          </div>
          <div className="partner_body">
            계정 해제 후 지속 비정상적인 계정 연동이 감지되는 경우 서비스 이용에
            제한이 있을 수 있습니다.
          </div>
        </div>
        <div className="partner_box">
          <div className="partner_title">[사용]</div>
          <div className="partner_body">
            다방코인 서비스는 다방 및 아지트 서비스에 회원가입 후 연동 완료 후에
            이용 가능하며 만 19세 미만의 회원은 이용이 제한될 수 있습니다.
          </div>
          <div className="partner_body">
            당사 및 제휴사의 방침에 따라 지정한 서비스나 상품을 구매 및 이용에
            활용 할 수 있습니다.
          </div>
          <div className="partner_body">
            다방코인을 서비스 이용 및 결제 등에 사용하기 위해선 다방포인트로
            전환하거나 당사가 제휴한 코인 및 마일리지 등의 포인트 서비스와 계정
            연동하여 전환 후 사용 할 수 있습니다.
          </div>
          <div className="partner_body">
            다방코인 1코인은 1원으로 청구 되며 다방포인트와 1:1로 전환됩니다.
            (다방코인 1Coin : 다방포인트 1Point)
          </div>
          <div className="partner_body">
            다방코인 등은 타인에게 양도하거나 대여 또는 담보의 목적으로 이용할
            수 없습니다.
          </div>
        </div>
        <div className="partner_box">
          <div className="partner_title">[전환]</div>
          <div className="partner_body">
            다방코인을 다방포인트로의 전환은 다방 어플리케이션에서만 가능합니다.
          </div>
          <div className="partner_body">
            다방코인은 아지트 어플리케이션을 통해 아지트 토큰(AZIT)으로 전환할
            수 있으며 및 아지트 토큰(AZIT) 실시간 시세에 따라 전환율이
            적용됩니다.
          </div>
          <div className="partner_body">
            아지트 토큰(AZIT)으로 전환 시 수수료가 부과될 수 있으며 상세내용은
            홈페이지 및 모바일 어플리케이션, 전환 서비스 이용 시 확인
            가능합니다.
          </div>
          <div className="partner_body">
            다방코인은 최소 100 Coin 부터 전환 할 수 있습니다.
          </div>
          <div className="partner_body">
            다방코인을 다방포인트 및 아지트 토큰(AZIT), 타 제휴 포인트로 전환한
            후에는 취소 할 수 없습니다.
          </div>
        </div>
        <div className="partner_box">
          <div className="partner_title">[한도]</div>
          <div className="partner_body">
            다방코인은 1회 100 ~ 100,000 Coin, 1일 100 ~ 500,000 Coin 까지 전환
            가능합니다.
          </div>
          <div className="partner_body">
            *한도는 당사 또는 제휴사의 정책이나 관련 법령에 따라 사전 고지 없이
            변경될 수 있습니다.
          </div>
        </div>
        <div className="partner_box">
          <div className="partner_title">[유효기간]</div>
          <div className="partner_body">
            다방코인은 적립월(또는 일)로부터 최대 1년(12개월) 이며 소멸하기 30일
            전부터 확인 가능합니다.
          </div>
          <div className="partner_body">
            이벤트로 적립된 다방코인은 별도의 유효기간이 설정 될 수 있습니다.
          </div>
        </div>
        <div className="partner_box">
          <div className="partner_title">[회원탈퇴]</div>
          <div className="partner_body">
            회원 탈퇴 전, 누적 다방코인이 존재하는 경우 잔여 코인을 사용하여
            소진 하여야 합니다.
          </div>
          <div className="partner_body">
            다만, 미소진 상태에서 회원 탈퇴 시, 유효기간과 무관하게 다방코인은
            소멸 됩니다.
          </div>
        </div>
        <div className="partner_box">
          <div className="partner_title">[부칙]</div>
          <div className="partner_body">
            다방코인과 관련된 정책 및 서비스 내용은 당사 및 제휴사의 사정에 의해
            별도 고지 없이 변경될 수 있습니다.
          </div>
          <div className="partner_body">
            이 정책은 2022년 08월 01일부터 적용됩니다.
          </div>
        </div>
        {/* <div className="partner_box">
      <div className="partner_title">{partnerName} 코인 유효기간</div>
      <div className="partner_body">- {partnerName}코인은 적립월로부터 최대 1년(12개월) 이며 소멸하기 30일 전부터 확인 가능합니다. <br/> - 이벤트로 적립된 {partnerName}코인은 별도의 유효기간이 설정될 수 있습니다. <br/> - 회원 탈퇴 시, 유효기간과는 무관하게 소멸 될 수 있습니다.</div>
    </div> */}
      </div>
    </>
  );
}

export default PartnerNotice;
