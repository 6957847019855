import { Map } from 'immutable'

const SET_CONNECTED = 'SET_CONNECTED'
const SET_CONNECTED_SUCCESS = 'SET_CONNECTED_SUCCESS'
const SET_CONNECTED_ERROR = 'SET_CONNECTED_ERROR'
const SET_SELECTEDWALLET = 'SET_SELECTEDWALLET'
const SET_SELECTEDWALLET_SUCCESS = 'SET_SELECTEDWALLET_SUCCESS'
const SET_SELECTEDWALLET_ERROR = 'SET_SELECTEDWALLET_ERROR'
const SET_LOGS = 'SET_LOGS'
const SET_LOGS_SUCCESS = 'SET_LOGS_SUCCESS'
const SET_LOGS_ERROR = 'SET_LOGS_ERROR'
const SET_PAGE = 'SET_PAGE'
const SET_PAGE_SUCCESS = 'SET_PAGE_SUCCESS'
const SET_PAGE_ERROR = 'SET_PAGE_ERROR'
const SET_INITWALLET = 'SET_INITWALLET'
const SET_INITWALLET_SUCCESS = 'SET_INITWALLET_SUCCESS'
const SET_INITWALLET_ERROR = 'SET_INITWALLET_ERROR'
const SET_CARD_CURRENT = 'SET_CARD_CURRENT'
const SET_CARD_CURRENT_SUCCESS = 'SET_CARD_CURRENT_SUCCESS'
const SET_CARD_CURRENT_ERROR = 'SET_CARD_CURRENT_ERROR'
const SET_EXPANDED = 'SET_EXPANDED'
const SET_EXPANDED_SUCCESS = 'SET_EXPANDED_SUCCESS'
const SET_EXPANDED_ERROR = 'SET_EXPANDED_ERROR'

const initialState = Map({
  connected: false,
  selectedWallet: undefined,
  logs: [],
  page: 0,
  initWallet: true,
  cardCurrentPage: 1,
  expanded: false,
})

export const setConnected = (val) => ({
  type: SET_CONNECTED,
  val,
})
export const setSelectedWallet = (val) => ({
  type: SET_SELECTEDWALLET,
  val,
})

export const setLogs = (val) => ({
  type: SET_LOGS,
  val,
})

export const setPage = (val) => ({
  type: SET_PAGE,
  val,
})

export const setInitWallet = (val) => ({
  type: SET_INITWALLET,
  val,
})

export const setCardCurrentPage = (val) => ({
  type: SET_CARD_CURRENT,
  val,
})

export const setExpanded = (val) => ({
  type: SET_EXPANDED,
  val,
})

export default function system(state = initialState, action) {
  switch (action.type) {
    case SET_CONNECTED:
    case SET_CONNECTED_SUCCESS:
    case SET_CONNECTED_ERROR:
      return state.setIn(['connected'], action.val)
    case SET_SELECTEDWALLET:
    case SET_SELECTEDWALLET_SUCCESS:
    case SET_SELECTEDWALLET_ERROR:
      return state.setIn(['selectedWallet'], action.val)
    case SET_LOGS:
    case SET_LOGS_SUCCESS:
    case SET_LOGS_ERROR:
      return state.setIn(['logs'], action.val)
    case SET_PAGE:
    case SET_PAGE_SUCCESS:
    case SET_PAGE_ERROR:
      return state.setIn(['page'], action.val)
    case SET_INITWALLET:
    case SET_INITWALLET_SUCCESS:
    case SET_INITWALLET_ERROR:
      return state.setIn(['initWallet'], action.val)
    case SET_CARD_CURRENT:
    case SET_CARD_CURRENT_SUCCESS:
    case SET_CARD_CURRENT_ERROR:
      return state.setIn(['cardCurrentPage'], action.val)
      case SET_EXPANDED:
      case SET_EXPANDED_SUCCESS:
      case SET_EXPANDED_ERROR:
        return state.setIn(['expanded'], action.val)
    default:
      return state
  }
}
