import Layout from "./layout";
import styles from "../styles/notice.css";
import { Container } from "react-bootstrap";

export default function NoticeDetail({ data }) {
  const createMarkup = (htmlcode) => {
    return {
      __html: htmlcode,
    };
  };

  return (
    <div className="container">
      <main>
        <Layout>
          <Container>
            <div className="noti_detail_box">
              <div className="noti_detail_title">
                <div>{data.category_name}</div>
                <div>{data.title}</div>
                <div>{data.updated_at.substring(0, 10)}</div>
              </div>
              <div className="noti_detail_body">
                <div style={{ fontFamily: "Pretendard" }}>
                  <div dangerouslySetInnerHTML={createMarkup(data.contents)} />
                </div>
              </div>
              <div>
                <div className="noti_back_button">
                  <a href="/notice">
                    <a>목록으로 돌아가기</a>
                  </a>
                </div>
              </div>
            </div>
          </Container>
        </Layout>
      </main>
      <style jsx>{`
        a {
          text-decoration: none;
          color: #2a323c;
        }
      `}</style>
    </div>
  );
}
