import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Spin } from 'antd'
import FaqList from '../components/faqList'
import { getFaq } from '../store/modules/faq'

function FaqContainer({ offset }) {
  const { data, loading, error } = useSelector((state) => state.faq.get_faq)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getFaq())
  }, [dispatch, offset])

  if (loading && !data)
    return (
      <div style={{ textAlign: 'center', paddingTop: '100px' }}>
        <Spin className="p-4" />
      </div>
    )
  if (error) return <div className="error-text" style={{ textAlign: 'center', paddingTop: '100px' }}>Error!</div>
  if (!data) return <div className="error-text" style={{ textAlign: 'center', paddingTop: '100px' }}>no data</div>
  return <FaqList data={data} />
}

export default FaqContainer
