import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Spin } from 'antd'
import { getEvent } from '../store/modules/event'
import EventDetail from '../components/eventDetail'
import { useParams } from 'react-router'

function EventDetailContainer() {
  // console.log(pid)
  const { pid } = useParams();
  console.log(pid);
  const { data, loading, error } = useSelector((state) => state.event.get_event)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getEvent({pid:pid}))
  }, [dispatch,pid])

  if (loading && !data)
    return (
      <div style={{ textAlign: 'center', paddingTop: '100px' }}>
        <Spin className="p-4" />
      </div>
    )
  if (error) return <div className="error-text" style={{ textAlign: 'center', paddingTop: '100px' }}>Error!</div>
  if (!data) return <div className="error-text" style={{ textAlign: 'center', paddingTop: '100px' }}>no data</div>
  return <EventDetail data={data} />
}

export default EventDetailContainer
