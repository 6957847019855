import Layout from "./layout";
import { Container } from "react-bootstrap";
import Marketb from "./marketb";

export default function NoticeDetail({ data }) {
  const htmlcode = data.contents;
  function createMarkup() {
    return {
      __html: htmlcode,
    };
  }
  return (
    <div className="container">
      <main>
        <Layout>
          <Container>
            <div className="noti_detail_box">
              <div className="noti_detail_title">
                <div></div>
                <div>{data.title}</div>
                <div>{data.updated_at.substring(0, 10)}</div>
              </div>
              <div className="noti_detail_body">
                {data.id === 18 ? (
                  <Marketb/>
                ) : (
                  <>
                    <div>
                      <img src={data.banner_link} width="100%" />
                    </div>
                    <div style={{ padding: "20px", fontFamily: "Pretendard" }}>
                      <div dangerouslySetInnerHTML={createMarkup()} />
                    </div>
                  </>
                )}
              </div>
              <div>
                <div className="noti_back_button">
                  <a href="/event">
                    <a>목록으로 돌아가기</a>
                  </a>
                </div>
              </div>
            </div>
          </Container>
        </Layout>
      </main>
      <style jsx>{`
        a {
          text-decoration: none;
          color: #2a323c;
        }
      `}</style>
    </div>
  );
}
