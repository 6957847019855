import * as eventAPI from "../../api/event";
import {
  createPromiseThunk,
  reducerUtils,
  handleAsyncActions,
} from "../../util/asyncUtils";

const GET_EVENTS = "GET_EVENTS";
const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
const GET_EVENTS_ERROR = "GET_EVENTS_ERROR";
const GET_EVENT = "GET_EVENT";
const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";
const GET_EVENT_ERROR = "GET_EVENT_ERROR";

const initialState = {
  get_events: reducerUtils.initial(),
  get_event: reducerUtils.initial(),
};

export const getEvents = createPromiseThunk(
  GET_EVENTS,
  eventAPI.getAllEvents
);
export const getEvent = createPromiseThunk(GET_EVENT, eventAPI.getOneEvent);

export default function system(state = initialState, action) {
  switch (action.type) {
    case GET_EVENTS:
    case GET_EVENTS_SUCCESS:
    case GET_EVENTS_ERROR:
      return handleAsyncActions(GET_EVENTS, "get_events")(state, action);
    case GET_EVENT:
    case GET_EVENT_SUCCESS:
    case GET_EVENT_ERROR:
      return handleAsyncActions(GET_EVENT, "get_event")(state, action);
    default:
      return state;
  }
}
